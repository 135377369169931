import {View, StyleSheet, Image} from 'react-native';
import React from 'react';
import theme from '@/style';
import LinearGradient from '@basicComponents/linear-gradient';
import Text from '@/components/basic/text';
import OpenCloseTab from './open-close-tab';
import TextInput from '@/components/basic/input-field/text-input';
import {SattaConfig, SattaConfigRes, SattaInfoResponse} from '../satta-service';
import DigitInput, {BettingType} from '../components/digit-input';
import {useTranslation} from 'react-i18next';
import Price from '@/components/business/price';
import Button from '@/components/basic/button';
const add = require('@components/assets/imgs/game/satta/satta-add.png');
import globalStore from '@/services/global.state';
export interface SattaInputDigitProps {
  selectType: SattaConfig;
  gameType: number;
  detail?: SattaInfoResponse | null;
  selectTab: 'open' | 'close';
  onTabItemPress: (status: 'open' | 'close') => void;
  selectOpen?: boolean;
  closeDigit?: string;
  openDigit?: string;
  digits: string;
  expectedValue: string;
  onChangeExpected?: (text: string) => void;
  changeDigit?: (s: 'open' | 'close', v: string) => void;
  handleAddCart?: (amount: string) => void;
}

const SattaInputDigit = ({
  selectType,
  gameType,
  selectTab,
  onTabItemPress,
  selectOpen = true,
  detail,
  closeDigit = '',
  openDigit = '',
  changeDigit,
  expectedValue = '',
  onChangeExpected,
  digits,
  handleAddCart,
}: SattaInputDigitProps) => {
  const {t} = useTranslation();

  const hiddenOpenAndClose = React.useMemo(() => {
    return ['Jodi', 'Full Sangam'].includes(selectType.value);
  }, [selectType]);

  const firstInputTip = React.useMemo(() => {
    const enterPrefix = 'satta.betting.tip.enter';
    const requirePrefix = 'satta.betting.tip.require';
    if (gameType === 1) {
      return {
        require: t(`${requirePrefix}.open`),
        enter: t(`${enterPrefix}.open`),
        completeDigit: 1,
      };
    }
    if (gameType === 2) {
      return {
        require: t(`${requirePrefix}.close`),
        enter: t(`${enterPrefix}.close`),
        completeDigit: 1,
      };
    }
    if ([4, 6, 8, 10, 12].indexOf(gameType) !== -1) {
      return {
        require: t(`${requirePrefix}.opens`),
        enter: t(`${enterPrefix}.opens`),
        completeDigit: 3,
      };
    }
    if ([5, 7, 9].indexOf(gameType) !== -1) {
      return {
        require: t(`${requirePrefix}.closes`),
        enter: t(`${enterPrefix}.closes`),
        completeDigit: 3,
      };
    }
    if (gameType === 3) {
      return {
        require: t(`${requirePrefix}.jodi`),
        enter: t(`${enterPrefix}.jodi`),
        completeDigit: 2,
      };
    }
    // 剩余的是Half Sangam Close
    return {
      require: t(`${requirePrefix}.openpanna`),
      enter: t(`${enterPrefix}.openpanna`),
      completeDigit: 1,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameType]);

  const secondInputTip = React.useMemo(() => {
    const enterPrefix = 'satta.betting.tip.enter';
    const requirePrefix = 'satta.betting.tip.require';
    // 只有Half Sangam和Full Sangam有第二个输入框
    if (gameType === 11 || gameType === 12) {
      return {
        require: t(`${requirePrefix}.closes`),
        enter: t(`${enterPrefix}.closes`),
        completeDigit: 3,
      };
    }
    if (gameType === 10) {
      return {
        require: t(`${requirePrefix}.closepanna`),
        enter: t(`${enterPrefix}.closepanna`),
        completeDigit: 1,
      };
    }
    return {
      require: '',
      enter: '',
      completeDigit: 1,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameType]);

  /** 当前赔率 */
  const currentOdds = React.useMemo(() => {
    const pannaConfig = detail?.matkaConfigRes;
    if (!pannaConfig) {
      return 0;
    }
    const configKeyMap: Record<string, keyof SattaConfigRes> = {
      Ank: 'singleDigital',
      Jodi: 'jodiDigital',
      SP: 'singlePanna',
      DP: 'doublePanna',
      TP: 'triplePanna',
      'Half Sangam': 'halfSangam',
      'Full Sangam': 'fullSangam',
    };
    return pannaConfig[configKeyMap[selectType.value] || 0] || 0;
  }, [selectType, detail]);

  // 计算获胜金额
  const digitWin = React.useMemo(() => {
    return Number(currentOdds) * Number(expectedValue);
  }, [currentOdds, expectedValue]);

  const onAdd = () => {
    if (
      (selectType.value === 'Half Sangam' ||
        selectType.value === 'Full Sangam') &&
      !closeDigit
    ) {
      globalStore.globalWaringTotal(secondInputTip?.require || '');
      return;
    }
    if (!expectedValue) {
      globalStore.globalWaringTotal(t('satta.betting.tip.require.amount'));
      return;
    }
    // 验证数据是否合法
    if (openDigit.length !== firstInputTip.completeDigit) {
      globalStore.globalWaringTotal(t('satta.betting.tip.check'));
      return;
    }
    if (
      (selectType.value === 'Half Sangam' ||
        selectType.value === 'Full Sangam') &&
      closeDigit.length !== secondInputTip.completeDigit
    ) {
      globalStore.globalWaringTotal(t('satta.betting.tip.check'));
      return;
    }

    if (+expectedValue < (detail?.minPrice || 0)) {
      globalStore.globalWaringTotal(
        t('satta.betting.tip.minAmount', {
          amount: detail?.minPrice || 0,
        }),
      );
      return;
    }
    handleAddCart?.(expectedValue);
  };

  const onClearAll = () => {
    onChangeExpected?.('20');
    changeDigit?.('close', '');
    changeDigit?.('open', '');
  };

  React.useEffect(() => {
    onClearAll();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectType, selectTab]);

  return (
    <>
      <View style={[styles.container]}>
        <LinearGradient colors={['#FFFFFF00', '#FFFFFF80']}>
          <View style={[theme.padding.topl]}>
            <View
              style={[theme.position.abs, {left: theme.paddingSize.xxl * 2}]}>
              <Image
                style={[
                  {width: theme.paddingSize.xxl, height: theme.paddingSize.s},
                ]}
                source={require('@components/assets/imgs/game/satta/satta-triangle.png')}
              />
            </View>
            <View
              style={[
                styles.tabContainer,
                theme.flex.row,
                theme.flex.centerByCol,
                theme.flex.between,
              ]}>
              <View style={[theme.flex.row, theme.flex.centerByCol]}>
                <Text
                  size="medium"
                  style={[
                    // eslint-disable-next-line react-native/no-inline-styles
                    {fontWeight: '500'},
                  ]}>
                  {selectType.digitTitle}
                </Text>
                <Text
                  style={[theme.margin.lefts]}
                  size="medium"
                  fontFamily="fontInterBold">
                  {digits}
                </Text>
              </View>
              {!hiddenOpenAndClose && (
                <OpenCloseTab
                  disabled={detail?.isClose === 1 || detail?.isClose === 2}
                  activeKey={selectTab}
                  onItemPress={onTabItemPress}
                />
              )}
            </View>
          </View>
        </LinearGradient>
        <View
          style={[theme.padding.lrl, theme.padding.tops, theme.padding.btml]}>
          <Text>{selectType.describe}</Text>
          <View
            style={[
              theme.margin.tbl,
              theme.flex.row,
              theme.flex.center,
              {gap: theme.paddingSize.s},
            ]}>
            <DigitInput
              digitList={detail?.allNum || []}
              bettingType={selectType.value as BettingType}
              digits={selectType.value === 'Full Sangam' || selectOpen ? 3 : 1}
              value={openDigit}
              onChangeText={tsr => changeDigit?.('open', tsr)}
              placeholder={firstInputTip.enter}
            />
            {(selectType.value === 'Half Sangam' ||
              selectType.value === 'Full Sangam') && (
              <>
                <Text size="medium">-</Text>
                <DigitInput
                  digitList={detail?.allNum || []}
                  bettingType={selectType.value as BettingType}
                  placeholder={secondInputTip.enter}
                  // onFocus={() => handleFocus(true)}
                  // onBlur={() => handleFocus()}
                  digits={
                    selectType.value === 'Full Sangam' || !selectOpen ? 3 : 1
                  }
                  value={closeDigit}
                  onChangeText={tsr => changeDigit?.('close', tsr)}
                />
              </>
            )}
          </View>
        </View>
      </View>
      <LinearGradient colors={['#DAE4EA', '#E6EEF2']} style={[theme.padding.l]}>
        <View style={[theme.flex.row, theme.flex.centerByCol]}>
          <Text style={[theme.margin.rights, theme.font.medium]} size="medium">
            {t('satta.betting.exwinning')}
          </Text>
          <Price
            textProps={{
              size: 'medium',
              fontFamily: 'fontInterBold',
            }}
            price={digitWin}
          />
        </View>
        <View
          style={[theme.flex.row, theme.flex.centerByCol, theme.margin.tops]}>
          <View style={[theme.flex.flex1, theme.margin.rights]}>
            <TextInput
              value={expectedValue}
              onValueChange={onChangeExpected}
              hasMax={false}
              style={styles.inputContainer}
              // eslint-disable-next-line react-native/no-inline-styles
              textInputStyle={{
                minHeight: 40,
                maxHeight: 40,
                height: 40,
              }}
              placeholder={t('satta.betting.tip.enter.amount')}
              // eslint-disable-next-line react-native/no-inline-styles
              containerStyle={[{marginBottom: 0}]}
            />
          </View>
          <Button type="linear-primary-reversal" onPress={onAdd}>
            <Image source={add} style={[theme.icon.xxs]} />
            <Text
              size="medium"
              fontFamily="fontInterBold"
              style={[theme.margin.leftxxs, {color: theme.basicColor.white}]}>
              {t('satta.betting.add')}
            </Text>
          </Button>
        </View>
      </LinearGradient>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#EEF4F8',
    zIndex: 100,
  },
  tabContainer: {
    borderBottomColor: theme.fontColor.grey,
    borderBottomWidth: 1,
    paddingHorizontal: 12,
    height: 32,
  },
  inputContainer: {
    borderWidth: 1,
    borderColor: theme.fontColor.grey,
    borderRadius: 4,
  },
});

export default SattaInputDigit;
