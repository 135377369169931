import Spin from '@/components/basic/spin';
import DetailNavTitle from '@/components/business/detail-nav-title';
import theme from '@/style';
import {BasicObject, SafeAny} from '@/types';
import {debounce, goBack, getUUID, transfor} from '@/utils';
import {useRoute} from '@react-navigation/native';
import React from 'react';
import {ScrollView, View} from 'react-native';
import SattaHeader from './satta-header';
import SattaBetSelect from './statta-bet-select';
import SattaTab from './satta-tab/satta-tab';
import SattaResult from './satta-tab/result/satta-result';
import OrderList from './satta-tab/order/order-list';
import SattaInputDigit from './satta-input-digit/satta.input.digit';
import ShopCart from '@/components/business/games/cart/cart';
import SattaCartList from './satta-cart-list/satta-cart-list';
import {
  SattaConfig,
  SattaInfoResponse,
  SattaOrderCreateDataContent,
  buyMatka,
  getSattaInfo,
  getOrderListSatta,
} from './satta-service';
import {useTranslation} from 'react-i18next';
import Winner from './satta-tab/winner/winner-list';
import globalStore from '@/services/global.state';
import RuleModal from '@/components/business/games/rule';
import SattaRule from './satta-rule';
import PaidShareModal from '@/components/business/paid-share-modal';
import {
  getShareAward,
  postReport,
  TReportType,
  TSourceType,
} from '@/services/global.service';

const GameSatta = () => {
  const user = localStorage.getItem('user') || '{}';
  const userId = JSON.parse(user).userId;
  const {params = {}} = useRoute();
  const {id, lotteryName} = params as BasicObject;
  const [loading, setLoading] = React.useState(false);
  const [ruleVisible, setRuleVisible] = React.useState(false);
  const [detail, setDetail] = React.useState<SattaInfoResponse | null>();
  const {t} = useTranslation();
  const [selectTab, setSelectTab] = React.useState<'open' | 'close'>('open');
  const selectOpen = React.useMemo(() => selectTab === 'open', [selectTab]);
  const [selectBetsKey, setSelectBetsKey] = React.useState<number>(0);
  const [tabActive, setTabActive] = React.useState(0);
  const [openDigit, setOpenDigit] = React.useState('');
  const [closeDigit, setCloseDigit] = React.useState('');
  const [expectedValue, setExpectedValue] = React.useState('20');
  const [buyList, setBuyList] = React.useState<SattaOrderCreateDataContent[]>(
    [],
  );
  const [version, setVersion] = React.useState(0);
  const [orderNum, setOrderNum] = React.useState(0);
  const totalOrder = React.useRef(0);
  const [popVisible, setPopVisible] = React.useState<boolean>(false);
  const [shareAward, setShareAward] = React.useState<number>(0);
  const [threeOrderData, setThreeOrderData] = React.useState<SafeAny>({});

  React.useEffect(() => {
    init().then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const init = async () => {
    try {
      setLoading(true);
      const info = await getSattaInfo(id);
      if (info) {
        setDetail(info);
      }
    } finally {
      setLoading(false);
    }
  };

  const betsTabs = React.useMemo(() => {
    if (!detail) {
      return [];
    }
    const baseConfig: SattaConfig[] = [
      {
        label: t('satta.betting.ank'),
        value: 'Ank',
        digitTitle: t('satta.betting.digitTitle.ank'),
        describe: t('satta.betting.describe.ank'),
        row: 1,
      },
      ...(detail?.isClose === 0
        ? [
            {
              label: t('satta.betting.jodi'),
              value: 'Jodi',
              digitTitle: t('satta.betting.digitTitle.jodi'),
              describe: t('satta.betting.describe.jodi'),
              row: 1,
            },
          ]
        : []),
      {
        label: t('satta.betting.sp'),
        value: 'SP',
        digitTitle: t('satta.betting.digitTitle.sp'),
        describe: t('satta.betting.describe.sp'),
        row: 1,
      },
      {
        label: t('satta.betting.dp'),
        value: 'DP',
        digitTitle: t('satta.betting.digitTitle.dp'),
        describe: t('satta.betting.describe.dp'),
        row: 1,
      },
      {
        label: t('satta.betting.tp'),
        value: 'TP',
        digitTitle: t('satta.betting.digitTitle.tp'),
        describe: t('satta.betting.describe.tp'),
        row: 1,
      },
      ...(detail?.halfStatus === 0 && detail.isClose === 0
        ? [
            {
              label: t('satta.betting.half'),
              value: 'Half Sangam',
              digitTitle: t('satta.betting.digitTitle.half'),
              describe: t('satta.betting.describe.half'),
              row: 2,
            },
          ]
        : []),
      ...(detail?.fullStatus === 0 && detail.isClose === 0
        ? [
            {
              label: t('satta.betting.full'),
              value: 'Full Sangam',
              digitTitle: t('satta.betting.digitTitle.full'),
              describe: t('satta.betting.describe.full'),
              row: 2,
            },
          ]
        : []),
    ];
    baseConfig.map((conf, index) => (conf.key = index));
    return baseConfig;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detail]);

  React.useEffect(() => {
    if (detail?.isClose === 1 || detail?.isClose === 2) {
      setSelectTab('close');
    }
  }, [detail]);

  const selectBets = React.useMemo(() => {
    if (betsTabs.length > 0) {
      return betsTabs[selectBetsKey];
    }
    return {} as SattaConfig;
  }, [selectBetsKey, betsTabs]);

  const gameType = React.useMemo(() => {
    /**
     * 游戏类型
     * 1、ankOpen 2、ankClose 3、jodi 4、SPOpen 5、SPColse 6、DPOpen
     * 7、DPClose 8、TPOpen 9、TPClose 10、Half SangamOpen 11、Half SangamClose 12、Full Sangam
     */
    if (selectBets.value === 'Ank') {
      return selectOpen ? 1 : 2;
    }
    if (selectBets.value === 'Jodi') {
      return 3;
    }
    if (selectBets.value === 'SP') {
      return selectOpen ? 4 : 5;
    }
    if (selectBets.value === 'DP') {
      return selectOpen ? 6 : 7;
    }
    if (selectBets.value === 'TP') {
      return selectOpen ? 8 : 9;
    }
    if (selectBets.value === 'Half Sangam') {
      return selectOpen ? 10 : 11;
    }
    return 12;
  }, [selectBets, selectOpen]);

  const onChangeExpected = (text: string) => {
    setExpectedValue(text);
  };

  const changeDigit = (status: 'open' | 'close', v: string) => {
    if (status === 'open') {
      setOpenDigit(v);
    } else {
      setCloseDigit(v);
    }
  };

  const digits = React.useMemo(() => {
    if (
      selectBets.value === 'Half Sangam' ||
      selectBets.value === 'Full Sangam'
    ) {
      return `${openDigit}-${closeDigit}`;
    }
    return openDigit || '-';
  }, [closeDigit, openDigit, selectBets]);

  const reverseGametype = React.useCallback((value: number) => {
    return (
      {
        1: 'AnkOpen',
        2: 'AnkClose',
        3: 'Jodi',
        4: 'SPOpen',
        5: 'SPClose',
        6: 'DPOpen',
        7: 'DPClose',
        8: 'TPOpen',
        9: 'TPClose',
        10: 'HSAOpen',
        11: 'HSAClose',
        12: 'FS',
      }[value] || 'AnkOpen'
    );
  }, []);

  const handleAddCart = (amount: string) => {
    setBuyList(list => {
      const copyList = [...list];
      const haveOne = copyList.find(
        item => item.digits === digits && item.gameType === gameType,
      );
      if (haveOne) {
        haveOne.amount += +amount;
        return copyList;
      }
      return [
        ...copyList,
        {
          digits,
          gameType,
          amount: +amount,
          type: reverseGametype(gameType),
        },
      ];
    });
    onClearAll();
  };

  const onDeleteOne = (item: SattaOrderCreateDataContent) => {
    setBuyList(list => list.filter(p => p.digits !== item?.digits));
  };

  const buyData = React.useMemo(() => {
    return buyList.reduce(
      (pre, cur) => {
        pre.price += cur.amount;
        pre.count += 1;
        return pre;
      },
      {
        price: 0,
        count: 0,
      },
    );
  }, [buyList]);

  const onClearAll = () => {
    onChangeExpected?.('20');
    changeDigit?.('close', '');
    changeDigit?.('open', '');
  };

  const onPay = async () => {
    if (loading) {
      return;
    }
    try {
      setLoading(true);
      const matkaBaseReqList = buyList.map(p => ({
        ...p,
        digits: p.gameType === 11 ? (p.digits + '').replace('-', '') : p.digits,
      }));
      const res = await buyMatka({
        matkaId: (detail?.id || '') + '',
        matkaBaseReqList,
      });
      if (res) {
        const shareUserId = localStorage.getItem('shareUserId');
        const [imStr, imGameCode] = (
          localStorage.getItem('PlayNow') || '-'
        ).split('-');
        if (shareUserId && imStr === 'im' && imGameCode === 'GameSatta') {
          postReport({
            reportType: TReportType.BETS,
            gameName: lotteryName,
            gameType: 'matka',
            userId,
            issueNo: (detail?.id || '') + '',
            sourceType: TSourceType.IM,
            shareUserId,
          });
          localStorage.removeItem('PlayNow');
        }
        if (!globalStore.imIcon) {
          globalStore.globalSucessTotal(
            t('paidSuccess.label.subTitle'),
            t('paidSuccess.tip.msg'),
          );
        }
        getShareAward('matka').then(award => {
          setShareAward(Math.floor(award));
          setPopVisible(true);
          getOrderListSatta(1, 1, 3).then(ret => {
            setThreeOrderData({
              userId,
              data: transfor(
                {
                  matkaId: (detail?.id || '') + '',
                  matkaBaseReqList,
                },
                'matka',
                {
                  rest: {
                    gameIconUrl: ret?.content[0]?.gameIconUrl || '',
                    gameName: lotteryName,
                    issNo: detail?.id || '',
                    openTime: getTimestamp(detail?.closeDraw),
                    totalAmount: matkaBaseReqList.reduce((r, c) => {
                      r += c.amount;
                      return r;
                    }, 0),
                  },
                },
              ),
            });
          });
        });
        setBuyList([]);
        onClearAll();
        if (tabActive !== 2) {
          totalOrder.current += 1;
          setOrderNum(totalOrder.current);
        }
        globalStore.updateAmount.next({gameType: 'Matka'});
        setVersion(version + 1);
      }
    } finally {
      setLoading(false);
    }
  };

  const getTimestamp = (time?: string) => {
    if (time == null) {
      return 0;
    }
    // 将 12 小时制时间（如：09:45PM）转化为当天的时间戳
    return (
      time
        .slice(0, -2)
        .split(':')
        .map(Number)
        .reduce((r, c, i) => {
          const ct = c * (i ? 60 : 3600) * 1000;
          r += ct;
          return r;
        }, 0) +
      (time.includes('PM') ? 12 * 60 * 60 * 1000 : 0) +
      new Date(new Date().toDateString()).getTime() +
      2 * 60 * 60 * 1000 +
      30 * 60 * 1000
    );
  };

  const onPayCheck = debounce(() => {
    onPay();
  }, 200);

  React.useEffect(() => {
    if (tabActive === 2 && totalOrder.current) {
      totalOrder.current = 0;
      setOrderNum(0);
    }
  }, [tabActive]);

  return (
    <View
      style={[theme.background.lightGrey, theme.flex.flex1, theme.flex.col]}>
      <DetailNavTitle
        title={'Satta Matka'}
        hideServer={true}
        onBack={() => goBack()}
      />
      <Spin loading={loading} style={[theme.flex.flex1]}>
        <View style={[theme.flex.flex1, theme.flex.basis0]}>
          <ScrollView
            stickyHeaderIndices={[3]}
            keyboardShouldPersistTaps="always">
            <SattaHeader
              open={detail?.openDraw}
              close={detail?.closeDraw}
              name={lotteryName}
              issueNum={detail?.id || ''}
              openResultNum={detail?.openResultNum}
              closeResultNum={detail?.closeResultNum}
              backImage={detail?.backImgWeb}
              showRule={() => setRuleVisible(true)}
            />
            <SattaBetSelect
              betsTabList={betsTabs}
              selectBetsKey={selectBetsKey}
              onItemPress={setSelectBetsKey}
            />
            <SattaInputDigit
              digits={digits}
              detail={detail}
              selectOpen={selectOpen}
              selectType={selectBets}
              gameType={gameType}
              selectTab={selectTab}
              onTabItemPress={setSelectTab}
              changeDigit={changeDigit}
              openDigit={openDigit}
              closeDigit={closeDigit}
              expectedValue={expectedValue}
              onChangeExpected={onChangeExpected}
              handleAddCart={handleAddCart}
            />
            <SattaTab
              orderNum={orderNum}
              activeKey={tabActive}
              onTabChange={setTabActive}
            />
            {tabActive === 0 && <SattaResult gameId={id} />}
            {tabActive === 1 && <Winner />}
            {tabActive === 2 && <OrderList refreshCode={version} />}
          </ScrollView>
        </View>
      </Spin>
      <PaidShareModal
        popVisible={popVisible}
        setPopVisible={setPopVisible}
        shareAward={shareAward}
        sharInfo={{
          gameCode: 'matka',
          orderNo: getUUID(),
          threeOrderData,
        }}
      />
      <ShopCart
        disable={buyList.length === 0}
        price={buyData.price}
        onClear={() => setBuyList([])}
        title={t('satta.betting.mybids')}
        onConfirm={onPayCheck}
        tip={`${buyData.count} ${t('satta.betting.bids')}`}>
        <SattaCartList cartList={buyList} onItemClear={onDeleteOne} />
      </ShopCart>
      <RuleModal
        title="Rule"
        visible={ruleVisible}
        scrollEnabled={false}
        onClose={() => setRuleVisible(false)}>
        <SattaRule id={detail?.id} />
      </RuleModal>
    </View>
  );
};

export default GameSatta;
